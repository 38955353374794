import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";

import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import stylesheet from "~/tailwind.css?url";
import { getPocketbase, getUser } from "./pb.server";
import twm from "./cn";
import { useState } from "react";
import { UsersResponse } from "pocketbase-types";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];

export async function loader({ request }: LoaderFunctionArgs) {
  const pb = getPocketbase(request);
  const user = getUser(pb) as UsersResponse;

  return json({
    user,
    ENV: {
      POCKETBASE_URL: process.env.POCKETBASE_URL,
      SENTRY_DSN: process.env.SENTRY_DSN,
    },
  });
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="es" className="h-full bg-gray-100">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

// TODO: Extract Dropdown component from this
function Nav({ user }: { user: UsersResponse | null }) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <nav className="border-gray-200 bg-white px-4 py-2.5">
      <div className="mx-auto flex min-h-8 max-w-screen-xl flex-wrap items-center justify-between">
        <div className="w-full flex-1">
          <Link to="/" className="text-blue-600 hover:text-blue-500">
            El Agora
          </Link>
        </div>

        <div className="ml-full flex items-center space-x-6">
          {user ? (
            <div className="relative">
              <button
                type="button"
                className="relative mx-3 flex rounded-full bg-gray-800 text-sm focus:ring-4 focus:ring-gray-300 md:mr-0 "
                aria-expanded={isDropdownOpen}
                onClick={() => setDropdownOpen(!isDropdownOpen)}
                // onBlur={() => setDropdownOpen(false)}
              >
                <span className="sr-only">Open user menu</span>
                {/* <img
              className="h-8 w-8 rounded-full"
              src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
              alt="user"
            /> */}
                <div className="relative h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                  <svg
                    className="absolute -left-1 h-10 w-10 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </button>

              <div
                className={twm(
                  "z-50 w-56 list-none divide-y divide-gray-100 rounded bg-white text-base shadow",
                  "absolute right-0 top-12",
                  {
                    hidden: !isDropdownOpen,
                  },
                )}
              >
                <div className="px-4 py-3 text-sm text-gray-900">
                  <div>{user?.name}</div>
                  <div className="truncate font-medium">{user?.email}</div>
                </div>
                <ul className="py-1 text-gray-500" aria-labelledby="dropdown">
                  <li>
                    <a
                      href="/"
                      className="pointer-events-none block px-4 py-2 text-sm hover:bg-gray-100 "
                    >
                      Perfil
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="pointer-events-none block px-4 py-2 text-sm hover:bg-gray-100 "
                    >
                      Ajustes
                    </a>
                  </li>
                </ul>

                <ul className="py-1 text-gray-500" aria-labelledby="dropdown">
                  <li>
                    <Link
                      to="/logout"
                      className="block px-4 py-2 text-sm hover:bg-gray-100"
                    >
                      Sign out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <Link
              to="/login"
              className=" text-sm  text-blue-600 hover:underline"
            >
              Login
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const { ENV, user } = useLoaderData<typeof loader>();
  return (
    <div className="flex h-full flex-col">
      <Nav user={user} />
      <main className="mx-auto w-full max-w-7xl flex-1 px-2 py-6 sm:px-6 lg:px-8">
        <Outlet />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
      </main>
    </div>
  );
}
